<template>
  <base-material-card
    :icon="icon"
    color="secondary"
    :title="title"
  >
    <v-row>
      <!-- <v-col
        cols="12"
        md="6"
      >
        search options
      </v-col> -->
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          clearable
        />
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-data-table
      :headers="headers"
      :items="companyClasses"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td>
              <router-link :to="`/${companyClass}/${item.id}`">
                {{ item.name }}
              </router-link>
            </td>
            <td>{{ item.in_force_count }}</td>
            <td>{{ item.renewal_count }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <table-footer
      :options="options"
      :total="total"
    />
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'

  export default {
    components: {
      TableFooter: () => import('../components/TableFooter'),
    },

    props: {
      title: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      companyClass: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      loading: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'In Force', value: 'in_force_count' },
        { text: 'Renewal', value: 'renewal_count' },
      ],
      companyClasses: [],
      options: {},
      total: 0,
      search: '',
      timeout: null,
    }),

    watch: {
      options: {
        handler () {
          this.getCompanyClasses()
        },
        deep: true,
      },
      search () {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.options.page = 1
          this.getCompanyClasses()
        }, 500)
      },
      '$route' (to, from) {
        this.getCompanyClasses()
      },
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async getCompanyClasses () {
        this.loading = true
        try {
          const { sortBy, sortDesc, page, itemsPerPage } = this.options
          let apiurl = `agencies/agency-classes?page=${page}&per_page=${itemsPerPage}&class_name=${this.companyClass}`
          if (this.search) {
            apiurl += `&query=${this.search.replace('&', '%26')}`
          }
          if (sortBy[0]) {
            const direction = sortDesc[0] ? 'desc' : 'asc'
            apiurl += `&direction=${direction}&sortBy=${sortBy[0]}`
          }
          const response = await axios.get(apiurl)
          this.companyClasses = response.data.data
          this.total = response.data.total
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loading = false
      },
    },
  }
</script>
